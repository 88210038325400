@font-face {
	font-family: "Linux Libertine";
	 src: url("//db.onlinewebfonts.com/t/dfb65e12b864428604262596b857d782.eot"); 
	 src: url("//db.onlinewebfonts.com/t/dfb65e12b864428604262596b857d782.eot?#iefix")
	  format("embedded-opentype"), url("//db.onlinewebfonts.com/t/dfb65e12b864428604262596b857d782.woff2") 
	  format("woff2"), url("//db.onlinewebfonts.com/t/dfb65e12b864428604262596b857d782.woff") format("woff"), url("//db.onlinewebfonts.com/t/dfb65e12b864428604262596b857d782.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/dfb65e12b864428604262596b857d782.svg#Linux Libertine") format("svg"); 
		
	}
	html {
		font-family: sans-serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
	}
	body {
		margin: 0;
		height:100%;
	}
	article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary {
		display: block;
	}
	audio,canvas,progress,video {
		display: inline-block;
		vertical-align: baseline;
	}
	audio:not([controls]) {
		display: none;
		height: 0;
	}
	[hidden],template {
		display: none;
	}
	a {
		background-color: transparent;
	}
	a:active,a:hover {
		outline: 0;
	}
	abbr[title] {
		border-bottom: 1px dotted;
	}
	b,strong {
		font-weight: bold;
	}
	dfn {
		font-style: italic;
	}
	h1 {
		font-size: 2em;
		margin: 0.67em 0;
	}
	mark {
		background: #ff0;
		color: #000;
	}
	small {
		font-size: 80%;
	}
	sub,sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}
	sup {
		top: -0.5em;
	}
	sub {
		bottom: -0.25em;
	}
	img {
		border: 0;
		max-width: 100%;
		
	}
	svg:not(:root) {
		overflow: hidden;
	}
	figure {
		margin: 1em 40px;
	}
	hr {
		box-sizing: content-box;
		height: 0;
	}
	pre {
		overflow: auto;
	}
	code,kbd,pre,samp {
		font-family: monospace, monospace;
		font-size: 1em;
	}
	button,input,optgroup,select,textarea {
		color: inherit;
		font: inherit;
		margin: 0;
	}
	button {
		overflow: visible;
	}
	button,select {
		text-transform: none;
	}
	button,html input[type="button"], input[type="reset"],input[type="submit"] {
		-webkit-appearance: button;
		cursor: pointer;
	}
	button[disabled],html input[disabled] {
		cursor: default;
	}
	button::-moz-focus-inner,input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	input {
		line-height: normal;
	}
	input[type="checkbox"],input[type="radio"] {
		box-sizing: border-box;
		padding: 0;
	}
	input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}
	input[type="search"] {
		-webkit-appearance: textfield;
		box-sizing: content-box;
	}
	input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	fieldset {
		border: 1px solid #c0c0c0;
		margin: 0 2px;
		padding: 0.35em 0.625em 0.75em;
	}
	legend {
		border: 0;
		padding: 0;
	}
	textarea {
		overflow: auto;
	}
	optgroup {
		font-weight: bold;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	td,th {
		padding: 0;
	}
	html {
		color: #222;
		font-size: 1em;
		line-height: 1.4;
	}
	::-moz-selection {
		background: #b3d4fc;
		text-shadow: none;
	}
	::selection {
		background: #b3d4fc;
		text-shadow: none;
	}
	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		margin: 1em 0;
		padding: 0;
	}
	audio,canvas,iframe,img,svg,video {
		vertical-align: middle;
	}
	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}
	textarea {
		resize: vertical;
	}
	.browserupgrade {
		margin: 0.2em 0;
		background: #ccc;
		color: #000;
		padding: 0.2em 0;
	}
	.hidden {
		display: none !important;
	}
	.visuallyhidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.visuallyhidden.focusable:active,.visuallyhidden.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
	.invisible {
		visibility: hidden;
	}
	
	@media only screen and (min-width: 35em) {
	}
	@media print,(-webkit-min-device-pixel-ratio: 1.25),(min-resolution: 1.25dppx),(min-resolution: 120dpi) {
	}
	@media print {
		*,*:before,*:after,*:first-letter,*:first-line {
			background: transparent !important;
			color: #000 !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
		a,a:visited {
			text-decoration: underline;
		}
		a[href]:after {
			content: " (" attr(href) ")";
		}
		abbr[title]:after {
			content: " (" attr(title) ")";
		}
		a[href^="#"]:after,a[href^="javascript:"]:after {
			content: "";
		}
		pre,blockquote {
			border: 1px solid #999;
			page-break-inside: avoid;
		}
		thead {
			display: table-header-group;
		}
		tr,img {
			page-break-inside: avoid;
		}
		img {
			max-width: 100% !important;
		}
		p,h2,h3 {
			orphans: 3;
			widows: 3;
		}
		h2,h3 {
			page-break-after: avoid;
		}
	}
	h1, h2, h3, h4, h5, h6 {
		color: #000;
		background: none;
		font-weight: normal;
		margin: 0;
		overflow: hidden;
		padding-top: 0.5em;
		padding-bottom: 0.17em;
	}
	
	p.siteSub {
		font-size: 92%;
	}
	body {
		background: url("/src/img/body-top.png") repeat-x top left #f6f6f6;
	}
	ul {
		list-style-type: none;
		list-style-image: none;
		
		padding: 0.3em 0 0 0;
	}
	h1 {
		line-height: 1.3;
		
		padding: 0;
		letter-spacing: 0;
		font-size: 2.3em;
	}
	a, a:visited {
		color: #0645ad;
		text-decoration: none;
	}
	a:hover {
		text-decoration: underline;
	}
	.wrapAll {
		width: 100%;
	}
	
	
	
	.navigation {
		padding: 0.15em 0 0 0.45em;
	}

	
	
	#simpleSearch {
		margin: 1em 1em 0em 0.5em;
		display: block;
		width: 267px;
		height: 23px;
		padding: 0 0 0 3px;
		float: right;
		overflow: hidden;
		border: solid 1px #aaa;
		background: #F6F6F6;
		background: -moz-linear-gradient(top, #F6F6F6 0%, #FFFFFF 100%);
		background: -webkit-linear-gradient(top, #F6F6F6 0%, #FFFFFF 100%);
		background: linear-gradient(to bottom, #F6F6F6 0%, #FFFFFF 100%);
	}
	#searchInput {
		background: transparent;
		border: none;
		vertical-align: top;
		height: 23px;
		width: 239px;
		font-size: 0.8em;
	}
	#submitSearch {
		background-image: url("/src/img/search.png");
		display: inline-block;
		background-position: center center;
		background-repeat: no-repeat;
		height: 23px;
		width: 23px;
		cursor: pointer;
	}
	.tabsRight {
		float: right;
		line-height: 0;
	}

	span.user {
		background-position: left top;
		background-repeat: no-repeat;
		background-image: url("/src/img/user-ico.png");
		padding-left: 15px !important;
	}
	
	.contentsHeader {
		font-family: sans-serif;
		font-weight: bold;
		text-align: center;
	}
	.hidePanel, .showPanel {
		float: right;
		margin: 0 5px;
		position: absolute;
		right: 20px;
		cursor: pointer;
		color: #0645ad;
		top: 4px;
	}
	
	.showPanel {
		display: none;
	}
	.minimizedPanel .showPanel {
		display: block;
	}
	.minimizedPanel .hidePanel {
		display: none
	}
	
	.minimizedPanel .contentsHeader {
		text-align: left;
	}
	.lavenderBox {
		background: #fdfdfd;
		border: 1px solid #a2a9b1;
		clear: both;
		font-size: 88%;
		text-align: center;
		padding: 3px;
		line-height: 1.7em;
		margin: 1em auto 0;
	}
	.lavenderBox .header {
		background: #ccccff;
		padding: 0.25em 1em;
		line-height: 1.5em;
		text-align: center;
		font-weight: bold;
		color: #0645ad;
		font-size: 115%;
		margin-bottom: 3px;
	}
	.lavenderBox .subtitle {
		background: #ddddff;
	}
	.linklist a:after {
		content: " · ";
		font-weight: bold;
	}
	.linklist a:last-child:after {
		content: "";
	}
	.linklist a {
		white-space: nowrap;
	}
	.categories {
		border: 1px solid #a2a9b1;
		background-color: #f8f9fa;
		padding: 5px;
		margin-top: 1em;
		font-size: 1.1em;
		clear: both;
	}
	.categories a:after {
		content: " | ";
		font-weight: bold;
	}
	.categories a:last-child:after {
		content: "";
	}
	.pagefooter {
		padding: 1.5em 1.3em 2em;
		font-size: 75%;
		line-height: 2em;
	}
	.pagefooter a {
		display: inline-block;
		margin-right: 1em;
	}
	@media screen and (max-width: 850px) {
		#searchInput {
			width: 110px;
		}
		#simpleSearch {
			width: 138px;
		}
	}
	@media screen and (max-width: 700px) {
		.tabsRight {
			display: none;
		}
	}
	@media screen and (max-width: 550px) {
		.sidebar {
			width: 6em;
			padding: 3px 0.5em 10px 0.5em;
		}
		.mainsection {
			margin: 0 0 0 7em;
		}
		.contentsPanel {
			width: auto;
		}
	}
	@media screen and (max-width: 400px) {
		#simpleSearch {
			display: none;
		}
	}
	.darkmode--activated p, .darkmode--activated li {
		color: #000;
	  }
	  
	  .button {
		isolation: isolate;
	  }
	  .mobile-menu li a {
		color: #000;
	  }
	  .darkmode--activated .logo {
		mix-blend-mode: difference;
	  }